export const tableItems = [
  {
    key: 'id',
    label: '用户ID',
    hide: true,
    primary: true
  }, {
    key: 'username',
    label: '用户名',
    component: 'input',
    rules: [
      { required: true, message: '请填写用户名', trigger: 'blur' },
      { pattern: /^\S{2,32}$/, message: '请正确填写用户名', trigger: 'blur' }
    ]
  }, {
    key: 'mobileNumber',
    label: '手机号码',
    component: 'input',
    rules: [
      { required: true, message: '请填写手机号码', trigger: 'blur' },
      { pattern: /^1[3-9][0-9]{9}$/, message: '请正确填写手机号码', trigger: 'blur' }
    ]
  }, {
    key: 'realName',
    label: '真实姓名',
    component: 'input',
    rules: [
      { required: true, message: '请填写真实姓名', trigger: 'blur' },
      { pattern: /^\S{2,32}$/, message: '请正确填写真实姓名', trigger: 'blur' }
    ]
  }, {
    key: 'password',
    label: '密码',
    component: 'password',
    hideOnView: true,
    rules: [
      { required: true, message: '请填写密码', trigger: 'blur' },
      { pattern: /^\S{6,32}$/, message: '请正确填写密码', trigger: 'blur' }
    ]
  }, {
    key: 'rePwd',
    label: '确认密码',
    component: 'password',
    hideOnView: true,
    rules: [
      { required: true, message: '请填写确认密码', trigger: 'blur' },
      { pattern: /^\S{6,32}$/, message: '请正确填写确认密码', trigger: 'blur' }
    ]
  }, {
    key: 'status',
    label: '状态',
    width: 100,
    slot: true,
    component: {
      type: 'switch',
      activeValue: 1,
      inactiveValue: 0
    }
  }, {
    type: 'actions',
    label: '操作',
    width: 200,
    fixed: 'right'
  }
]

export const pwdItems = [
  {
    key: 'password',
    label: '密码',
    component: 'password',
    rules: [
      { required: true, message: '请填写密码', trigger: 'blur' },
      { pattern: /^\S{6,32}$/, message: '请正确填写密码', trigger: 'blur' }
    ]
  }, {
    key: 'rePwd',
    label: '确认密码',
    component: 'password',
    rules: [
      { required: true, message: '请填写确认密码', trigger: 'blur' },
      { pattern: /^\S{6,32}$/, message: '请正确填写确认密码', trigger: 'blur' }
    ]
  }
]